import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  container: {
    position: "absolute",
    zIndex: 9999,
  },
  toastContainer: {
    width: "50%",
    wordBreak: "break-all",
  },
});
