import { combineReducers } from "redux";
import { IAuthState, IToastProps, LoaderState } from "../type";
import { authReducer } from "./auth_reducer";
import { loaderReducer } from "./loader_reducer";
import { toastReducer } from "./toast_reducer";
import { bulkUploadReducer } from "./bulk_upload_reducer";
import { languageReducer } from "./language_reducer";
export interface IRootAppReducerState {
  auth: IAuthState;
  loader: LoaderState;
  infoToast: IToastProps;
}

export const rootReducer = combineReducers({
  auth: authReducer,
  loader: loaderReducer,
  infoToast: toastReducer,
  bulkUpload: bulkUploadReducer,
  language: languageReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
