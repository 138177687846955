
export const SHOW_LOADER = 'ShowLoader';

export const showLoader = (flag: boolean) => {
    return {
        type: SHOW_LOADER,
        payload: flag
    };
};

