import { legacy_createStore as createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga'
import { rootReducer } from '../reducers';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import rootSaga from '../sagas';

export type AppState = ReturnType<typeof rootReducer>;

const persistConfig = {
  key: 'root',
  storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const sagaMiddleWare = createSagaMiddleware();

const middlewares = [sagaMiddleWare];

const middleWareEnhancer = applyMiddleware(...middlewares);

export const store = createStore(
  persistedReducer,
  composeWithDevTools(middleWareEnhancer)
);

export default function prepareAppStore() {
  sagaMiddleWare.run(rootSaga);

  const persistor = persistStore(store, {});
  return { store, persistor };
}

