import { track, init, setUserId } from "@amplitude/analytics-browser";
import { Analytics } from "./type";

export class AmplitudeAnalytics implements Analytics {
  apiKey: string;
  isInitialized = false;

  constructor(apiKey: string) {
    this.apiKey = apiKey;
  }

  private initialize() {
    if (!this.isInitialized) {
      init(this.apiKey, {
        defaultTracking: true,
      });
      this.isInitialized = true;
    }
  }

  setUser(id: string) {
    this.initialize();
    setUserId(id);
  }

  trackEvent(name: string, data?: Record<string, unknown>) {
    this.initialize();
    track(name, data);
  }
}
