import { SHOW_LOADER } from "../actions/loader_actions";
import { IActionParams, LoaderState } from "../type";
import _ from "./../../Utils/lodash";

export const LoaderInitialState: LoaderState = {
  showLoader: false,
};

export const loaderReducer = (
  state = LoaderInitialState,
  action: IActionParams = {}
): LoaderState => {
  if (action.type === SHOW_LOADER) {
    return _.extend({}, state, { showLoader: action.payload });
  } else {
    return state;
  }
};
