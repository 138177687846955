import { lazy, Suspense } from "react";
import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { RootState } from "../redux/reducers";
import { RoutePaths } from "./../Constants/routeConstants";
const PageNotFound = lazy(() => import("../Components/PageNotFound"));
const Home = lazy(() => import('./../Components/Home'));

const AuthRoutes = () => {

  const baseUrl = useSelector((state: RootState) => state.auth.baseUrl);
  
  return (
      <Routes>
        {baseUrl === "" && (
          <>
            <Route
              path={RoutePaths.Dashboard}
              element={
                <Suspense fallback={null}>
                  <Home/>
                </Suspense>
              }
            />
            <Route
              path={RoutePaths.PageNotFound}
              element={
                < Suspense fallback={null} >
                  <PageNotFound />
                </Suspense >
              }
            />
          </>
        )}
      </Routes >
  );
};

export default AuthRoutes;
