import { takeEvery, call, put } from "redux-saga/effects";
import {
  logoutApi,
  sendOtpEmailApi,
  verifyOtpApi,
  logoutFromAllDeviceApi,
} from "../../Api/GenericApiCalls";
import {
  LOGOUT,
  SEND_OTP_EMAIL,
  VERIFY_OTP,
  LOGOFF_FROM_ALL_DEVICE,
  resetAction,
  setVerifyOtpAction,
  userAuthTokenAction,
  setUserNameAction,
  SendOtpEmail,
  VerifyOtp,
  setShowLogOffAction,
  userTokenAction,
} from "../actions/auth_actions";
import { showToaster } from "../actions/toaster_actions";
import { ToastTypes } from "../../Constants/enums";
import { SagaIterator } from "redux-saga";
import i18next from "i18next";

export function* sendOtpEmailSaga({
  payload,
}: SendOtpEmail): SagaIterator<void> {
  try {
    yield put(setVerifyOtpAction(false));
    const response = yield call(sendOtpEmailApi, payload);
    if (response?.status === 200) {
      yield put(setVerifyOtpAction(true));
      yield put(
        showToaster({
          text: response?.data?.message,
          type: ToastTypes.Success,
          time: 5000,
        })
      );
    } else {
      yield put(
        showToaster({
          text: response?.data?.message,
          type: ToastTypes.Error,
          time: 5000,
        })
      );
    }
  } catch (error) {
    yield put(
      showToaster({
        text: i18next?.t("dashboard.error_occurred_message"),
        type: ToastTypes.Error,
        time: 5000,
      })
    );
  }
}

export function* verifyOtpSaga({ payload }: VerifyOtp): SagaIterator<void> {
  try {
    yield put(setShowLogOffAction(false));
    const response = yield call(verifyOtpApi, payload);
    if (response?.status === 200) {
      yield put(userAuthTokenAction(response?.data?.token));
      yield put(setUserNameAction(response?.data?.user));
    } else if (response?.status === 429 && response?.data?.message === 'MAX_DEVICE_LOGIN_LIMIT_REACHED') {
      yield put(userTokenAction(response?.data?.token));
      yield put(setShowLogOffAction(true));
    } else {
      yield put(
        showToaster({
          text: response?.data?.message,
          type: ToastTypes.Error,
          time: 5000,
        })
      );
    }
  } catch (error) {
    yield put(
      showToaster({
        text: "Error Occurred while verifying OTP",
        type: ToastTypes.Error,
        time: 5000,
      })
    );
  }
}

export function* logoutSaga(): SagaIterator<void> {
  try {
    const response = yield call(logoutApi);
    if (response?.status === 200) {
      yield put(resetAction());
    } else {
      yield put(resetAction());
      yield put(
        showToaster({
          text: response?.data?.message,
          type: ToastTypes.Error,
          time: 5000,
        })
      );
    }
  } catch (error) {
    yield put(
      showToaster({
        text: "Error occurred while Logging Out Applcation",
        type: ToastTypes.Error,
        time: 5000,
      })
    );
  }
}

export function* logoutFromAllDeviceSaga(): SagaIterator<void> {
  try {
    const response = yield call(logoutFromAllDeviceApi);
    if (response?.status === 200) {
      yield put(
        showToaster({
          text: response?.data,
          type: ToastTypes.Success,
          time: 5000,
        })
      );
      yield put(resetAction());
    } else {
      yield put(resetAction());
      yield put(
        showToaster({
          text: response?.data?.message,
          type: ToastTypes.Error,
          time: 5000,
        })
      );
    }
  } catch (error) {
    yield put(
      showToaster({
        text: i18next?.t("login.sign_out_all_error_message"),
        type: ToastTypes.Error,
        time: 5000,
      })
    );
  }
}

export function* watchAuth() {
  yield takeEvery(SEND_OTP_EMAIL, sendOtpEmailSaga);
  yield takeEvery(VERIFY_OTP, verifyOtpSaga);
  yield takeEvery(LOGOUT, logoutSaga);
  yield takeEvery(LOGOFF_FROM_ALL_DEVICE, logoutFromAllDeviceSaga)
}
