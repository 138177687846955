import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { showToaster } from '../../redux/actions/toaster_actions';
import { RootState } from '../../redux/reducers';
import { useStyles } from './styles';

const Toasters: React.FC = () => {
    // classes
    const classes = useStyles();
    const dispatch = useDispatch();
    const toastProps = useSelector((state: RootState) => state.infoToast);

    const removeToastProps = () => {
        dispatch(showToaster({
            text: '',
            type: '',
            time: 0
        }));
    }
    const renderToast = () => {
        if (toast.isActive(toastProps.text)) {
            return <></>;
        }
        const props = {
            position: toast.POSITION.TOP_CENTER,
            autoClose: toastProps.time,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            toastId: toastProps.text,
            onClose: () => { removeToastProps() }
        };
        switch (toastProps.type) {
            case 'success':
                toast.success(toastProps.text, props);
                break;
            case 'info':
                toast.info(toastProps.text, props);
                break;
            case 'warn':
                toast.warn(toastProps.text, props);
                break;
            case 'error':
                toast.error(toastProps.text, props);
                break;
            default:
                break;
        }
    }
    return (
        <div className={classes.container}>
            <ToastContainer autoClose={false} draggable={false} pauseOnFocusLoss={false} className={classes.toastContainer}/>
            {
                toastProps.text && renderToast()
            }
        </div>
    );
};

export default Toasters;
