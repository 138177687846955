import React, { Suspense, lazy } from "react";
import AuthRoutes from "../../Routes/authRoutes";
import { useStyles } from "./styles";

const Header = lazy(() => import("../../Common/Header"));

const Dashboard: React.FC = () => {
  const classes = useStyles();
  return (
    <div>
      <Suspense fallback={null}>
        <Header />
      </Suspense>
      <div className={classes.containerHelper}>
          <AuthRoutes />
      </div>
    </div>
  );
};

export default Dashboard;
