import { TranslationDBType, I18nType } from "./type";

export const setLanguageOfApp = (translationDB: TranslationDBType, i18n: I18nType) => {
  i18n.addResourceBundle(
    "en",
    "translation",
    translationDB.en.translation,
    true,
    true
  );
  i18n.addResourceBundle(
    "ja",
    "translation",
    translationDB.ja.translation,
    true,
    true
  );
  i18n.reloadResources();
};

export const getDefaultSelectedLanguage = (i18n: I18nType) => {
  try {
    const translationDB = localStorage.getItem(
      "LANGUAGE_TRANSLATION"
    );

    if (translationDB) {
      setLanguageOfApp(JSON.parse(translationDB), i18n);
    }
  } catch (error) {
    console.log(error);
  }
};
