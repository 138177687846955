import { makeStyles } from '@mui/styles';
import { ASSETS_PATH } from '../../Constants/contants';

export const useStyles = makeStyles({
  contentHelper: {
    backgroundImage: `url(${ASSETS_PATH}Login_Background.jpg)`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    height: '100%',
    position: 'absolute',
    width: '100%',
    backgroundPosition: 'center center'
  },
  paperHelper: {
    marginInline: 'auto',
    width: '564px',
    height: '320px',
    marginTop: '2rem'
  },
  innerContentHelper: {
    textAlign: 'center',
    paddingTop: 30,
    paddingLeft: 40,
    paddingRight: 40
  },
  iconHelper: {
    height: 'auto !important',
    width: 'auto !important'
  },
  emailLabelHelper: {
    textAlign: 'start'
  },
  signInButtondHelper: {
    textTransform: 'unset !important' as 'unset',
    width: '-webkit-fill-available',
    margin: '30px 80px !important',
    height: '40px'
  },
  Select: {
    '& ul': {
      maxHeight: '20vh'
    }
  },
  loginContainerHelper: {
    width: 'max-content', marginInline: 'auto', marginTop: '1rem'
  },
  countrySelectionHelper: {
    height: '40px',
    background: '#F3F4F6',
    color: '#696F88',
    textAlign: 'left'
  },
  selectExpandMoreHelper: {
    position: 'absolute', right: '3px'
  },
  otpinputs: {
    gap: '10px'
  },
  imageHeight: {
    height: "120px"
  },
  otpinput: {
    height: '2rem',
    width: '2rem !important',
  }
});
