import { SET_LANGUAGE } from "../actions/language_action";
import { IActionParams, LanguageState } from "../type";
import _ from "./../../Utils/lodash";

export const LanguageInitialState: LanguageState = {
  lang: 'ja',
};

export const languageReducer = (
  state = LanguageInitialState,
  action: IActionParams = {}
): LanguageState => {
  if (action.type === SET_LANGUAGE) {
    return _.extend({}, state, { lang: action.payload });
  } else {
    return state;
  }
};
