import { createTheme } from "@mui/material/styles";

const ICON_BASE_PATH = process.env.PUBLIC_URL;

export const ASSETS_PATH = `${ICON_BASE_PATH}/assets/`;

export const ImagesUrl = {
  portalLogo: `${ASSETS_PATH}Web_Portal_Logo.svg`,
  plantImage: `${ASSETS_PATH}Plant_Icon.svg`,
  loaderImage: `${ASSETS_PATH}loader.gif`,
  uploadImage: `${ASSETS_PATH}bulkupload/upload.svg`,
  downloadImage: `${ASSETS_PATH}bulkupload/download.svg`
}


export const THEME = createTheme({
  palette: {
    primary: {
      main: '#0071CD'
    },
    success: {
      main: '#14803C'
    },
    info: {
      main: '#232630'
    },
    error: {
      main: '#CF3537'
    }
  },
  typography: {
    fontFamily: 'Noto Sans',
    subtitle1: {
      fontWeight: 700,
      fontSize: 20
    },
    subtitle2: {
      fontWeight: 600,
      fontSize: 14
    },
    body1: {
      fontWeight: 500,
      fontSize: 16
    },
    body2: {
      fontWeight: 400,
      fontSize: 12
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'unset'
        },
        outlined: {
          borderColor: '#DFE2E7'
        }
      }
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
        disableTouchRipple: true

      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          height: '44px',
          color: '#696F88',
          fontSize: '14px'
        },


      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&:hover': {
            background: '#C6E6FF'
          }
        }
      }
    },
    MuiButtonGroup: {
      styleOverrides: {
        grouped: {
          ':not(:last-of-type)': {
            borderColor: '#CFD3DB'
          }
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderTopLeftRadius: '6px',
          borderTopRightRadius: '6px'
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          fontSize: '14px',
          zIndex: 9

        },


      }
    }
    ,
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: '14px'
        }
      },
    },
    MuiModal: {
      styleOverrides: {
        root: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }
      }
    }
  }
});


export const BulkDataTypes = [
  {
    name: "Organisation",
    disable: false,
  },
  {
    name: "User",
    disable: false,
  },
  {
    name: "Project",
    disable: true,
  },
  {
    name: "Task Group",
    disable: true,
  },
  {
    name: "Task",
    disable: true,
  },
  {
    name: "Field",
    disable: true,
  },
];

