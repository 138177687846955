import _ from '../../Utils/lodash';
import { HIDE_TOAST, SHOW_TOAST } from '../actions/toaster_actions';
import { IActionParams, IToastProps } from '../type';


export const toastInitialState: IToastProps = {
    text: '',
    type: '',
    time: 5000
};

export const toastReducer = (state = toastInitialState, action: IActionParams = {}): IToastProps => {
    switch (action.type) {
        case SHOW_TOAST:
            return _.extend({}, state, action.payload);
        case HIDE_TOAST:
            return _.extend({}, state, toastInitialState);
        default: { return state; }
    }
};
