import { Box, styled, Typography } from '@mui/material';

export const OuterBox = styled(Box)(
    {
        width: '100vw',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column'
    }
);
export const InnerBox = styled(Box)(
    {
        width: '350px',
        height: 'auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column'
    }
);

export const ErroMessage = styled(Typography)({
    fontWeight: 700,
    fontSize: 20,
    color: '#CF3537',
    lineHeight: '28px',
    textAlign: 'center'
});
export const ErroMessageDescription = styled(Typography)({
    fontWeight: 400,
    fontSize: 14,
    color: '#14151C',
    lineHeight: '22px',
    textAlign: 'center'
});
