import { SET_BULK_UPLOAD_DETAILS } from "../actions/bulk_upload_actions";
import { IActionParams, IBulkUploadState } from "../type";
import _ from "./../../Utils/lodash";

export const bulkUploadInitialState:IBulkUploadState = {
  uploadDetails: [],
};

export const bulkUploadReducer = (
  state = bulkUploadInitialState,
  action: IActionParams = {}
): IBulkUploadState => {
  if (action.type === SET_BULK_UPLOAD_DETAILS) {
    return _.extend({}, state, { uploadDetails: action.payload });
  } else {
    return state;
  }
};
