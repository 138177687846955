import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./translation/en.json";
import ja from "./translation/ja.json";

export const languageResources = {
  en: { translation: en },
  ja: { translation: ja },
};

i18next.use(initReactI18next).init({
  compatibilityJSON: "v3",
  lng: "ja",
  fallbackLng: "en",
  resources: languageResources,
});

export default i18next;
