// App
export const APP_INITIALIZATION = "Risocare Portal App Loaded";
//Login
export const LOGIN_INITIALIZATION = "Login Screen Viewed";
export const EMAIL_MANUALLY = "Email Typed Manually";
export const LOGIN_SEND_OTP_CLICK =
  "User clicked on Proceed Button to Send OTP";
export const OTP_VERIFICATION_INITIALIZATION = "OTP Popup Shown";
export const OTP_RESEND_LINK_PRESS = "OTP resend link pressed";
export const OTP_MANUALLY = "OTP Typed Manually";
export const OTP_CONTINUE_BUTTON_PRESS =
  "OTP Verification continue button pressed";
export const LOG_OUT_CLICK = "User clicked on Log Out Button";
// Dasboard Bulk Upload
export const BULK_UPLOAD_INITIALIZATION = "Bulk Upload - Screen Viewed";
export const BULK_UPLOAD_FILE_UPLOAD_CLICK = "Bulk Upload - Upload Button is cliked";
export const BULK_UPLOAD__FILE_UPLOADED =
  "Bulk Upload - Excel file is uploaded";
export const BULK_UPLOAD_DOWNLOAD_CLICK =
  "Bulk Upload - Excel file download link clicked";
export const LANGUAGE_CHANGED = "User clicked on English / Japanese Button";

