import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Icon from "@mui/material/Icon";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React, { useCallback, useEffect, useState } from "react";
import { ImagesUrl } from "../../Constants/contants";
import { useStyles } from "./loginStyles";
import OtpInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/reducers";
import {
  verifyOtpAction,
  sendOtpEmailAction,
  setVerifyOtpAction,
  setShowLogOffAction,
  logOffFromAllDeviceAction,
  resetAction,
} from "../../redux/actions/auth_actions";
import { analytics } from "../../analytics";
import {
  EMAIL_MANUALLY,
  LOGIN_INITIALIZATION,
  LOGIN_SEND_OTP_CLICK,
  OTP_CONTINUE_BUTTON_PRESS,
  OTP_MANUALLY,
  OTP_RESEND_LINK_PRESS,
  OTP_VERIFICATION_INITIALIZATION,
} from "../../analytics/constants";
import { useTranslation } from "react-i18next";

const Login: React.FC = () => {
  const classes = useStyles();
  const [email, setEmail] = useState<string>("");
  const [otp, setOtp] = useState<string>("");
  const [showOtpModal, setShowOtpModal] = useState<boolean>(false);
  const [showLogOffModal, setShowLogOffModal] = useState<boolean>(false);
  const verifyOtp = useSelector((state: RootState) => state.auth.verifyOtp);
  const isMaxLogInAttempt = useSelector((state: RootState) => state?.auth?.isMaxLogInAttempt);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    analytics.trackEvent(LOGIN_INITIALIZATION);
  }, []);

  useEffect(() => {
    if (verifyOtp) {
      analytics.trackEvent(OTP_VERIFICATION_INITIALIZATION);
      setShowOtpModal(true);
      dispatch(setVerifyOtpAction(false));
    }
  }, [verifyOtp]);

  useEffect(() => {
    if (isMaxLogInAttempt) {
      setShowOtpModal(false);
      setShowLogOffModal(true);
      dispatch(setShowLogOffAction(false));
    }
  }, [isMaxLogInAttempt]);


  const handleClose = useCallback(() => setShowOtpModal(false), []);
  const handleCloseLogOffModal = useCallback(() => {
    setShowLogOffModal(false)
    dispatch(resetAction())
  }, [dispatch]);

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setEmail(value);
    analytics.trackEvent(EMAIL_MANUALLY, { email: `${value}@syngenta.com` });
  };

  const handleProceedtoSendOtp = useCallback(() => {
    dispatch(sendOtpEmailAction(`${email}@syngenta.com`));
    analytics.trackEvent(LOGIN_SEND_OTP_CLICK, {
      email: `${email}@syngenta.com`,
    });
    setOtp("");
  }, [email, dispatch]);

 
  const handleProceedtoLogOffAll = useCallback(() => {
    dispatch(logOffFromAllDeviceAction());
    setShowLogOffModal(false);
  }, [dispatch]);
 

  const handleReSendOtp = useCallback(() => {
    dispatch(sendOtpEmailAction(`${email}@syngenta.com`));
    analytics.trackEvent(OTP_RESEND_LINK_PRESS, {
      email: `${email}@syngenta.com`,
    });
  }, [email, dispatch]);

  const handleVerifyOtp = useCallback(() => {
    analytics.trackEvent(OTP_CONTINUE_BUTTON_PRESS, {
      email: `${email}@syngenta.com`,
    });
    dispatch(
      verifyOtpAction({
        email: `${email}@syngenta.com`,
        otp,
      })
    );
  }, [email, otp, dispatch]);

  const changeOtp = (otp: string) => {
    setOtp(otp);
    analytics.trackEvent(OTP_MANUALLY);
  };

  return (
    <div className={classes.contentHelper}>
      <div>
        <Box className={classes.loginContainerHelper}>
          <div>
            <img
              className={classes.imageHeight}
              src={ImagesUrl.portalLogo}
              alt="Risocare admin web portal"
            />
          </div>
        </Box>
      </div>
      <Paper className={classes.paperHelper}>
        <div className={classes.innerContentHelper}>
          <Icon className={classes.iconHelper}>
            <img src={ImagesUrl.plantImage} alt="Plant" />
          </Icon>
          <Typography variant="subtitle1">
            {t("login.login_message")}
          </Typography>
          <Typography fontSize={14}>{t("login.login_sub_header")}</Typography>
          <FormControl variant="standard" fullWidth>
            <Typography
              className={classes.emailLabelHelper}
              variant="subtitle2"
            >
              {t("login.email_label")}
            </Typography>
            <TextField
              InputProps={{
                style: {
                  height: "40px",
                  background: "#F3F4F6",
                  color: "#696F88",
                },
                endAdornment: <>@syngenta.com</>,
                id: "login-email-input",
              }}
              fullWidth
              value={email}
              onChange={handleEmailChange}
            />
          </FormControl>
          <Button
            color="success"
            data-testid="proceed-button"
            className={classes.signInButtondHelper}
            variant="contained"
            disabled={!email}
            onClick={(event: React.MouseEvent<HTMLElement>) => {
              event.preventDefault();
              handleProceedtoSendOtp();
            }}
          >
            <Typography fontSize={12} variant="subtitle2">
              {t("login.proceed")}
            </Typography>
          </Button>
        </div>
      </Paper>
      <Dialog open={showOtpModal} onClose={handleClose}>
        <DialogTitle>{t("login.otp_header")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {`${t("login.otp_sub_header")}${email}@syngenta.com.`}
          </DialogContentText>
          <Typography className={classes.emailLabelHelper} variant="subtitle2">
            {t("login.otp_label")}
          </Typography>
          <OtpInput
            value={otp}
            containerStyle={classes.otpinputs}
            inputStyle={classes.otpinput}
            onChange={changeOtp}
            numInputs={6}
            renderSeparator={" "}
            renderInput={(props) => (
              <input data-testid="otp-input" {...props} />
            )}
          />
          <Button onClick={handleReSendOtp}>{t("login.resend_otp")}</Button>
        </DialogContent>
        <DialogActions>
          <Button data-testid="cancel-button" onClick={handleClose}>
            {t("login.cancel")}
          </Button>
          <Button
            variant="contained"
            data-testid="continue-button"
            disabled={otp.length < 6}
            onClick={handleVerifyOtp}
          >
            {t("login.continue")}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={showLogOffModal} onClose={handleCloseLogOffModal}>
        <DialogTitle>{t("login.login_pending")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t("login.max_login_limit_msg")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button data-testid="cancel-button" onClick={handleCloseLogOffModal}>
            {t("login.cancel")}
          </Button>
          <Button
            variant="contained"
            data-testid="continue-button"
            onClick={handleProceedtoLogOffAll}
          >
            {t("login.sign_out_all")}
          </Button>
        </DialogActions>
      </Dialog>

    </div>
  );
};

export default Login;
