import React from "react";
import { useSelector } from "react-redux";
import { ImagesUrl } from "../../Constants/contants";
import { RootState } from "../../redux/reducers";
import { useStyles } from "./styles";

const Loader: React.FC = () => {
  // classes
  const classes = useStyles();

  const loaderStatus = useSelector((state: RootState) => {
    return state.loader.showLoader;
  });
  return (
    <React.Fragment>
      {loaderStatus && (
        <div className={classes.loaderContainer}>
          <img
            className={classes.progressHelper}
            alt="loader"
            src={ImagesUrl.loaderImage}
          />
        </div>
      )}
    </React.Fragment>
  );
};

export default Loader;
