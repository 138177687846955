export const SET_LANGUAGE = "SET_LANGUAGE";

export type SetLanguage = {
  type: typeof SET_LANGUAGE;
  payload: string;
};

export const setLanguageAction = (lang: string) => {
  return {
    type: SET_LANGUAGE,
    payload: lang,
  };
};
