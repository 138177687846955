import { VerifyOtpPayload } from "../type";

export const AUTH_TOKEN = "AUTH_TOKEN";
export const SET_USERNAME = "SET_USERNAME";
export const SEND_OTP_EMAIL = "SEND_OTP_EMAIL";
export const VERIFY_OTP = "VERIFY_OTP";
export const SET_VERIFY_OTP = "SET_VERIFY_OTP";
export const LOGOUT = "Logout";
export const RESET = "Reset";
export const SET_SHOW_LOGOFF_MODAL = "SET_SHOW_LOGOFF_MODAL";
export const USER_TOKEN = "USER_TOKEN";
export const LOGOFF_FROM_ALL_DEVICE = "LOGOFF_FROM_ALL_DEVICE";

export type SetVerifyOtp = {
  type: typeof SET_VERIFY_OTP;
  payload: boolean;
};

export type UserAuthToken = {
  type: typeof AUTH_TOKEN;
  payload: string;
};

export type SetUserName = {
  type: typeof SET_USERNAME;
};

export type SendOtpEmail = {
  type: typeof SEND_OTP_EMAIL;
  payload: string;
};

export type Logout = {
  type: typeof LOGOUT;
};

export type VerifyOtp = {
  type: typeof VERIFY_OTP;
  payload: VerifyOtpPayload;
};

export type Reset = {
  type: typeof RESET;
};

export const setVerifyOtpAction = (verify: boolean) => {
  return {
    type: SET_VERIFY_OTP,
    payload: verify,
  };
};

export const userAuthTokenAction = (token: string) => {
  return {
    type: AUTH_TOKEN,
    payload: token,
  };
};

export const setUserNameAction = (userName: string) => {
  return {
    type: SET_USERNAME,
    payload: userName,
  };
};

export const setShowLogOffAction = (isMaxLogInAttempt: boolean) => {
  return {
    type: SET_SHOW_LOGOFF_MODAL,
    payload: isMaxLogInAttempt,
  };
};

export const userTokenAction = (token: string) => {
  return {
    type: USER_TOKEN,
    payload: token,
  };
};

export const logOffFromAllDeviceAction = () => {
  return {
    type: LOGOFF_FROM_ALL_DEVICE,
  };
};

export const sendOtpEmailAction = (email: string) => {
  return {
    type: SEND_OTP_EMAIL,
    payload: email,
  };
};

export const verifyOtpAction = (payload: VerifyOtpPayload) => {
  return {
    type: VERIFY_OTP,
    payload,
  };
};

export const logoutAction = () => {
  return {
    type: LOGOUT,
  };
};

export const resetAction = () => {
  return {
    type: RESET,
  };
};
