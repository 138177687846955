import { IToastProps } from "../type";

export const HIDE_TOAST = "HIDE_TOAST";
export const SHOW_TOAST = "SHOW_TOAST";

export type ShowToaster = {
  type: typeof SHOW_TOAST;
  payload: IToastProps;
};

export type HideToaster = {
  type: typeof HIDE_TOAST;
};

export const showToaster = (toastInfo: IToastProps) => {
  return {
    type: SHOW_TOAST,
    payload: toastInfo,
  };
};

export const hideToaster = () => {
  return {
    type: HIDE_TOAST,
  };
};

export type ToasterActions = ShowToaster | HideToaster;
