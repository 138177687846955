import { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import Login from '../Components/Login';
import { RoutePaths } from './../Constants/routeConstants';

const UnAuthRoutes: React.FC = () => {
    return (
        <Routes>
            <Route
                path={RoutePaths.Login}
                element={
                    <Login />
                }
            />
            <Route
                path={RoutePaths.PageNotFound}
                element={
                    <Suspense fallback={null}>
                        <Login />
                    </Suspense>
                }
            />
        </Routes>
    );
};

export default UnAuthRoutes;
