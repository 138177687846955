import { Button } from '@mui/material';
import React, { ErrorInfo } from 'react';
import { ErroMessage, InnerBox, OuterBox } from './style';
import { ConnectedProps, connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { logoutAction } from '../../redux/actions/auth_actions';

type ErrorBoundaryProps = ConnectedProps<typeof connector>;
  
class ErrorBoundary extends React.Component<ErrorBoundaryProps, {
    hasError: boolean,
    error: unknown, 
    errorInfo: unknown
}> {

    // Constructor for initializing Variables etc in a state
    // Just similar to initial line of useState if you are familiar
    // with Functional Components
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = {
            hasError: false,
            error: null,
            errorInfo: null
        };
    }

    // This method is called if any error is encountered
    componentDidCatch(error: Error, errorInfo: ErrorInfo) {

        // Catch errors in any components below and
        // re-render with error message
        this.setState({
            error: error,
            errorInfo: errorInfo
        });

        // You can also log error messages to an error
        // reporting service here
    }

    // This will render this component wherever called
    render() {
        if (this.state.errorInfo) {

            // Error path
            return (
                <OuterBox className='OuterBox'>
                    <InnerBox>
                        <img width={100} src={process.env.PUBLIC_URL + '/assets/InternalServerProblem.svg'} alt="error" />
                        <ErroMessage>Sorry, we have run into an issue</ErroMessage>
                        <Button
                            variant='contained'
                            color='success'
                            fullWidth sx={{ my: '1rem' }}
                            onClick={() => {
                                this.props.logout();
                                window.location.reload();
                            }}
                        >
                            Try Again
                        </Button>
                    </InnerBox>
                </OuterBox>
            );
        }
        // Normally, just render children, i.e. in
        // case no error is Found
        return this.props.children;
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        logout: bindActionCreators(logoutAction, dispatch),
    }
}
const connector = connect(null, mapDispatchToProps);
export default connector(ErrorBoundary);
