import _ from '../../Utils/lodash'
import {
    AUTH_TOKEN,
    RESET,
    SET_VERIFY_OTP,
    SET_USERNAME,
    SET_SHOW_LOGOFF_MODAL,
    USER_TOKEN,
} from '../actions/auth_actions'
import { IAuthState, IActionParams } from '../type'

export const initialState: IAuthState = {
    authToken: '',
    baseUrl: '',
    userName: '',
    verifyOtp: false,
    isMaxLogInAttempt: false,
    userToken: '',
}

export function authReducer(
    state = initialState,
    action: IActionParams = {},
): IAuthState {
    switch (action.type) {
        case AUTH_TOKEN: {
            return _.extend({}, state, { authToken: action.payload })
        }
        case SET_VERIFY_OTP: {
            return _.extend({}, state, { verifyOtp: action.payload })
        }
        case RESET: {
            return _.extend({}, initialState)
        }
        case SET_USERNAME: {
            return _.extend({}, state, { userName: action.payload })
        }
        case SET_SHOW_LOGOFF_MODAL: {
            return _.extend({}, state, { isMaxLogInAttempt: action.payload })
        }
        case USER_TOKEN: {
            return _.extend({}, state, { userToken: action.payload })
        }
        default: {
            return state
        }
    }
}
